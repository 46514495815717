import React, { useEffect, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import PageHeader, {
  ActionButton,
  ImportExportButton, TrashButton, WhiteButton,
} from "../../../components/layout/page-header";
import {useDispatch, useSelector} from "react-redux";
import {CATALOG, PRODUCT, PRODUCTS, PRODUCT_DETAIL, ARCHIVE} from "../../endpoints";
import {getPathParam, getWebPathParam} from "../../../utils/converter";
import AddProduct from "./add-product";
import { useTranslation } from "react-i18next";
import {getProductsFilter} from "../../../api/catalog";
import ActionsMenu from "../../../components/common/table/actions-menu";
import {
  getPermission,
  permissionGroup,
  permissionSpecific,
  permissionType,
  sp
} from "../../../constants/permissions";
import ListSection from "../../../components/layout/list-section";
import OptionalLink from "../../../components/common/table/optional-link";
import TableDetail from "../../../components/common/list/table-detail";
import ProductCell from "../../../components/common/table/product-cell";
import NumberFormat from "react-number-format";
import ImportLog from "./import/import-log";
import ImportProduct from "./import/import-product";
import CardDetail from "../../../components/common/list/card-detail";
import {COMPANY, RESTORE} from "../../../api/endpoints";
import RemovePopup from "../../../components/common/popup/remove-popup";
import {ArchiveIcon, PlusIcon, TrashIcon} from "@heroicons/react/outline";
import SubscriptionWrapper, {
  hasSubscriptionPermission
} from "../../../components/partials/restricted/subscription-wrapper";
import {subscriptionUsageLimits} from "../../../reducers/subscriptionReducer";
import ImportErrorPopup from "../../../components/common/popup/import-error-popup";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";
import ConfirmPopup from "../../../components/common/popup/confirm-popup";
import DynamicFilterMenu from "../../../components/common/table/dynamic-filter-menu";
import {generateParamsFormLocation} from "../../../constants/dynamic-filter";
import {getCustomFormat} from "../../../utils/timeUtils";
import ExportProduct from "./export/export-product";
import BasePermissionWrapper, {hasPermissionFor} from "../../../components/partials/restricted/base-permission-wrapper";
import { refreshFilters } from "../../../reducers/userCompanyReducer";
import { StatusBarIcon } from "../../../components/common/alert/status-bar-icon";
import CloneProduct from "./clone-product";



const importType = 'product';
const ARCHIVED = 'deleted';
const pageLimit = 15;

const Products = () => {
  const { loggedCompany } = useSelector((state) => state.user);
  const { permissions, subscriptions } = useSelector((s) => s.userCompany);
  const isSubImportActive = hasSubscriptionPermission(subscriptions, sp.IMPORT_PRODUCT_MARKETPLACE_PACKAGE);
  const { company } = useSelector((state) => state.userCompany);

  const [isFetchProductsLoading, setFetchProductsLoading] = useState(true);
  const [productList, setProductList] = useState({
    products: [],
    total_rows: 0,
  });
  const location = useLocation();

  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
        "page"
    );
    if(pageNumber){
      return parseInt(((pageNumber * pageLimit) - pageLimit));
    }
    return 0;
  }


  const checkSearch = () => {
    const querySearch = new URLSearchParams(window.location.search).get(
        "querySearch"
    );
    if(querySearch){
      return querySearch;
    }
    return "";
  }


  const [offset, setOffset] = useState(checkOffset());
  const [query, setQuery] = useState(checkSearch());
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpload, setOpenUpload] = useState(false);
  const [isOpenExport, setOpenExport] = useState(false);

  const [isOpenImportLog, setOpenImportLog] = useState(false);

  const [lastImport, setLastImport] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);

  const [isOpenRemove, setOpenRemove] = useState(false);
  const [removeDetail, setRemoveDetail] = useState({});

  const [isOpenImportError, setOpenImportError] = useState(false);
  const [ImportErrorCode, setImportErrorCode] = useState(false);
  const [activeGroup, setActiveGroup] = useState(null);

  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [confirmDetail, setConfirmDetail] = useState({});

  const [selectAll, setSelectAll] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));
  const [dynamicFilters, setDynamicFilters] = useState(false);


  const canView =  hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.VIEW);
  const canDelete =  hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.DELETE);
  const canCreate =  hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionType.CREATE);

  const canArchive = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_ARCHIVE);
  const canImport = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_IMPORT);
  const canExport = hasPermissionFor(permissions, permissionGroup.PRODUCT, permissionSpecific.PRODUCT_EXPORT);

  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [defaultParams, setDefaultParams] = useState(false);
  const [sortBy, setSortBy] = useState({ accessor: null, type: null, id: null, position: 0 });


  const [isOpenClone, setOpenClone] = useState(false);
  const [productDetail, setProductDetail] = useState({});



  const dispatch = useDispatch();

  const { t } = useTranslation();

  const columns = [
    {
      header: t("app.form.name"),
      id: 'name',
      accessor: "name"
    },
    (company?.product_manage_status ?? false) && {
      header: t("app.products.status"),
      id: 'status',
      accessor: r => r.status
    },
    {
      header: t("app.products.product_code"),
      id: 'product_reference',
      accessor: r => r.product_reference,
    },
    {
      header: t("app.products.manufacture_code"),
      id: 'manufacture_code',
      accessor: r => r.manufacture_reference,
    },
    {
      header: t("app.products.volume"),
      id: 'volume',
      accessor: r => r.default_dimension.volume,
    },
    {
      header: t("app.products.quantity_box"),
      id: 'quantity_box',
      accessor: r => r.default_dimension.volume,
    },
    {
      header: t("app.products.country_origin"),
      id: 'volume',
      accessor: r => r.country_origin.name,
    },
    {
      header: 'Hs Code',
      id: 'hs_code',
      accessor: r => r.hs_code,
    },
    {
      header: t("app.products.last_list_date"),
      id: 'last_list_date',
      accessor: r => new Date(r.last_price_date).getTime()
    },
    {
      header: "",
      accessor: null
    }
  ].filter(Boolean);



  const tabs = [
    { name: t("app.products.product_all"), group: null },
    canArchive && { name: t("app.products.product_archived"), group: ARCHIVED }
  ].filter(Boolean);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    function fetchProducts() {
      if (loggedCompany) {
        setFetchProductsLoading(true);
        getProductsFilter({
          company_id: loggedCompany.id,
          params: { limit: pageLimit, filter: activeGroup, offset: offset, query: query, remove_default: !defaultParamsUsed },
          data: activeDynamicFilter,
          signal,
        })
          .then((response) => {
            setProductList(response);
            setFetchProductsLoading(false);
            setResetPagination(false);
            if(response.filters){
              setDynamicFilters(response.filters);
            }

            if (response.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)) {
              setDefaultParams('?' + response.default_filter);
            }

          })
          .catch((e) => {
            if(signal.aborted){
              setFetchProductsLoading(true);
            }else{
              setProductList({ products: [], total_rows: 0 });
              setFetchProductsLoading(false);
            }
          });
      }
    }

    fetchProducts();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [offset, query, loggedCompany, needRefresh, activeGroup, activeDynamicFilter]);



  const handleSelectAll = () => {
    if(productList.products.length > 0){
      setSelectAll(!selectAll);
      setIsCheck(productList.products.map(li => li.id));
      if (selectAll) {
        setIsCheck([]);
      }
    }
  };

  const handleClick = e => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };


  return (

      <SubscriptionWrapper requiredPermission={sp.CATALOG_INTERNAL_PACKAGE} renderBaseOnFail={true}>
        <BasePermissionWrapper
            requiredPermissions={[getPermission(permissionGroup.PRODUCT, permissionType.LIST)]}
          
        >

        <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {/* Page header */}
              <PageHeader
                  title={t("app.products.products")}
                  subtitle={isSubImportActive ? t("app.products.view_import_log") : false}
                  importGroup={[getPermission(permissionGroup.PRODUCT, permissionSpecific.PRODUCT_IMPORT)]}
                  onSubtitleClick={() => {
                    setOpenImportLog(true)
                  }}
                  onNeedRefresh={(r) => {
                    if(r.error_description){
                      setImportErrorCode(r.error_description);
                      setOpenImportError(true);
                    }else{
                      dispatch(refreshFilters({ company_id: loggedCompany.id }));
                      setNeedRefresh(!needRefresh);
                    }
                  }}
                  importType={importType}
                  lastImport={lastImport}
              >

                {
                    canCreate && (
                       <div className="mb-3 md:mb-0">
                        <ActionButton
                          icon={
                            <PlusIcon
                              className="w-4 h-4 "
                            />
                          }
                          onClick={() => {
                            setIsOpen(true);
                          }}
                          text={t("app.products.new_product")}
                        />
                      </div>
                    )
                }

                {
                    (canImport || canExport) && (
                        <ImportExportButton
                            canImport={canImport}
                            canExport={canExport}
                            onImport={() => {
                              setOpenUpload(true);
                            }}
                            onExport={() => {
                              setOpenExport(true);
                            }}
                            text={t("app.products.import_products")}
                        />
                    )
                }
              </PageHeader>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

              <UnderlineTab
                  tabs={tabs}
                  activeGroup={activeGroup}
                  setActiveGroup={(r) => {
                    setActiveGroup(r);
                    setOffset(0);
                  }}
              />


              <ListSection
                pageLimit={pageLimit}
                searchLength={1}
                title={t("app.products.products")}
                columns={columns}
                totalRows={productList.total_rows}
                list={productList?.products ?? []}
                onSearch={ (r) => {
                  setResetPagination(true);
                  setOffset(0);
                  setQuery(r); 
                }}
                isLoading={isFetchProductsLoading}
                paginationOnChange={setOffset}
                isDynamicPagination={true}
                isDynamicSearch={true}
                isTableSort={true}
                sortBy={sortBy}
                onSortBy={(n) => {
                  setSortBy(n);
                }}
                mobileRow={ProductMobRow}
                desktopRow={ProductDeskRow}
                selectAll={selectAll}
                resetPagination={resetPagination}
                handleSelectAll={handleSelectAll}
                hasCheckbox={ canArchive && (activeGroup !== ARCHIVED)}
                activeDynamicFilter={true}
                activeFixedColumn={true}
                extras={{
                  canView,
                  canDelete,
                  canArchive,
                  canCreate,
                  onRemove: (r, detail) => {
                    setRemoveDetail(detail);
                    setOpenRemove(r);
                  },
                  onRestore: (r, detail) => {
                    setConfirmDetail(detail);
                    setOpenConfirm(r);
                  },
                  onClone: (r, detail) => {
                    setProductDetail(detail);
                    setOpenClone(r);
                  },
                  activeGroup,
                  isCheck,
                  handleClick
                }}
              >

                {
                    (isCheck.length > 0 && (canDelete || canArchive)) && (
                        <div className="inline-flex items-center justify-center">
                          <p className={'text-sm font-medium text-gray-900 mr-2'}>{isCheck.length} {t("app.common.item_selected")}</p>

                          {
                            canDelete && (
                                  <TrashButton
                                      onClick={() => {

                                        const removeDetail = {
                                          title: t("app.products.remove_title"),
                                          message: t("app.products.remove_description"),
                                          endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCTS]) + '?products='+isCheck
                                        };
                                        setOpenRemove(true);
                                        setRemoveDetail(removeDetail);
                                      }}
                                      icon={
                                        <TrashIcon
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                        />
                                      }
                                  />
                              )
                          }


                          {
                            canArchive && (
                                  <div className={"ml-3"}>
                                    <WhiteButton
                                        onClick={() => {
                                          const removeDetail = {
                                            title: t("app.products.archive_title"),
                                            message: t("app.products.archive_description"),
                                            endpoint: getPathParam([COMPANY, loggedCompany.id, ARCHIVE, PRODUCTS]) + '?products='+isCheck
                                          };
                                          setOpenRemove(true);
                                          setRemoveDetail(removeDetail);
                                        }}
                                        icon={
                                          <ArchiveIcon
                                              className="w-4 h-4"
                                              aria-hidden="true"
                                          />
                                        }
                                    />
                                  </div>
                              )
                          }

                        </div>
                    )
                }

                <DynamicFilterMenu
                    filters={dynamicFilters}
                    defaultParams={defaultParams}
                    isLoading={isFetchProductsLoading && offset === 0}
                    filterType={'product'}
                    onChange={(r) => {
                      setDynamicDateFilter(r);
                      setOffset(0);
                      const querySearch = new URLSearchParams(window.location.search).get("querySearch");
                      if (querySearch) {
                        setQuery(querySearch);
                      }else{
                        setQuery('');
                      }
                      setDefaultParamsUsed(true);
                      setDefaultParams(false);
                    }}
                />


              </ListSection>
            </div>
          </div>

          {isOpenRemove && canDelete && (
              <RemovePopup
                  isOpen={isOpenRemove}
                  setIsOpen={setOpenRemove}
                  detail={removeDetail}
                  setDetail={(r) => {
                    setProductList(r);
                    setIsCheck([]);
                    setSelectAll(false);
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                    dispatch(refreshFilters({ company_id: loggedCompany.id }));
                  }}
              />
          )}

          {isOpen && canCreate && <AddProduct isOpen={isOpen} setIsOpen={setIsOpen} />}


        {isOpenClone && canCreate && <CloneProduct isOpen={isOpenClone} setIsOpen={setOpenClone} productDetail={productDetail} />}


          {isOpenImportError && (
              <ImportErrorPopup
                  isOpen={isOpenImportError}
                  errorCode={ImportErrorCode}
                  setIsOpen={() => {
                    setImportErrorCode(false);
                    setOpenImportError(false);
                    setNeedRefresh(!needRefresh);
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                  }}
              />
          )}

          {isOpenConfirm && (
              <ConfirmPopup
                  isOpen={isOpenConfirm}
                  setIsOpen={setOpenConfirm}
                  detail={confirmDetail}
                  onConfirmed={(response) => {
                    setIsCheck([]);
                    setSelectAll(false);
                    setNeedRefresh(!needRefresh);
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                  }}
              />
          )}

          <SubscriptionWrapper requiredPermission={sp.IMPORT_PRODUCT_MARKETPLACE_PACKAGE} renderBaseOnFail={false}>
            {
              canImport && (
                  <>
                    <ImportProduct
                        isOpen={isOpenUpload}
                        setIsOpen={setOpenUpload}
                        importGroup={importType}
                        onImportComplete={(r) => {
                          setLastImport(r);
                          dispatch(subscriptionUsageLimits(loggedCompany.id));
                        }}
                    />

                    <ImportLog
                        isOpen={isOpenImportLog}
                        setIsOpen={setOpenImportLog}
                        type={importType}
                    />
                  </>
                )
            }
          </SubscriptionWrapper>


          {canExport && (
              <ExportProduct
                  isOpen={isOpenExport}
                  setIsOpen={setOpenExport}
              />
          )}



        </BasePermissionWrapper>
      </SubscriptionWrapper>
  );
};

const ProductMobRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);
  const { company } = useSelector((state) => state.userCompany);

  const isCheck = extras.isCheck.includes(element.id);

  const removeDetail = {
    title: t("app.products.remove_title"),
    message: t("app.products.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT, element.id])
  };

  const archiveDetail = {
    title: t("app.products.archive_title"),
    message: t("app.products.archive_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT, element.id, ARCHIVE])
  };




  return (
      <li key={element.id} className={isCheck ? 'flex items-center gap-4 p-4 bg-solitude-100' : 'flex items-center gap-4 p-4'}>
      <OptionalLink
        isLinked={false}
        to={getWebPathParam([CATALOG, PRODUCT, element.id, PRODUCT_DETAIL])}
        className="flex-grow"
      >
        <div className="pb-4 items-center flex justify-between">


         <div className="flex items-center ">
           {
               extras.canArchive && extras.activeGroup !== ARCHIVED && (
                   <div className={"flex justify-between items-center"}>
                     <input type="checkbox"
                            id={element.id}
                            onChange={extras.handleClick} checked={isCheck}
                            className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                   </div>
               )
           }
            <ProductCell product={element} shotTitle={element.has_alternative ? t('app.catalog.has_alternative_product') : false}  isActiveManufacture={false} hideArchiveStatus={extras.activeGroup === ARCHIVED} />

         </div>

          {extras.canView && extras.activeGroup !== ARCHIVED ? (
            <ActionsMenu>
              <Link to={getWebPathParam([CATALOG, PRODUCT, element.id, PRODUCT_DETAIL])}>
                {t("app.common.edit")}
              </Link>

              {(extras.canCreate) && (
                <button className={'text-left'} onClick={() => {
                  extras.onClone(true, element);
                }}
                >
                  {t("app.common.clone")}
                </button>
              )}

              {(extras.canDelete && element.is_removable) && (
                  <button className={'text-left'} onClick={() => {
                    extras.onRemove(true, removeDetail);
                  }}
                  >
                    {t("app.common.remove")}
                  </button>
              )}


              {extras.canArchive && (
                  <button className={'text-left'} onClick={() => {
                    extras.onRemove(true, archiveDetail);
                  }}
                  >
                    {t("app.customers.archive")}
                  </button>
              )}

            </ActionsMenu>
          ) : null}


          {extras.canDelete && extras.activeGroup === ARCHIVED ? (
              <ActionsMenu>
                <button className={'text-left'} onClick={() => {

                  const restoreDetail = {
                    title: t("app.products.restore_title"),
                    message: t("app.products.restore_description"),
                    endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT, element.id, RESTORE])
                  };

                  extras.onRestore(true, restoreDetail);
                }}
                >
                  {t("app.products.restore")}
                </button>
              </ActionsMenu>
          ) : null}
        </div>
        <div className="flex flex-col">



          <CardDetail title={t("app.products.manufacture_code")}>
            {element.manufacture_reference}
          </CardDetail>



          <CardDetail title={t("app.products.volume")}>
            <NumberFormat
                value={element?.default_dimension?.volume ?? 0}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                suffix={' m³'}
            />
          </CardDetail>

          <CardDetail title={t("app.products.quantity_box")}>
            <NumberFormat
                value={element?.default_dimension?.qty_per_packaging ?? 0}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={2}
                suffix={element.unit_value ? ' '+element.unit_value.symbol : ''}
            />
          </CardDetail>


          <CardDetail title={"Hs Code"}>
            {element?.hs_code ?? '-'}
          </CardDetail>


          <CardDetail title={t("app.products.country_origin")}>
            {element?.country_origin?.name ?? '-'}
          </CardDetail>

          <CardDetail title={t("app.products.last_list_date")}>
            {getCustomFormat(element.last_price_date, 'DD/MM/YYYY')}
          </CardDetail>


          {
            (company?.product_manage_status ?? false) && (
              <CardDetail title={t("app.products.status")}>
                <StatusBarIcon status={element.status} /> 
              </CardDetail>
            )
          }
        

          

        </div>
      </OptionalLink>
    </li>
  );
};

const ProductDeskRow = ({ element, extras }) => {
  const { t } = useTranslation();
  const { loggedCompany } = useSelector((s) => s.user);
  const isCheck = extras.isCheck.includes(element.id);
  const { company } = useSelector((state) => state.userCompany);

  const removeDetail = {
    title: t("app.products.remove_title"),
    message: t("app.products.remove_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT, element.id])
  };

  const archiveDetail = {
    title: t("app.products.archive_title"),
    message: t("app.products.archive_description"),
    endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT, element.id, ARCHIVE])
  };


  return (
    <tr key={element.id} className={isCheck ? 'bg-solitude-100' : ''}>



      {
          extras.canArchive &&  extras.activeGroup !== ARCHIVED && (

          <TableDetail extraClass={isCheck ? "relative w-12 px-6 sm:w-16 sm:px-8 bg-solitude-important" : "relative w-12 px-6 sm:w-16 sm:px-8"}>
                {
                    isCheck && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )
                }
                <input type="checkbox"
                       id={element.id}
                       onChange={extras.handleClick} checked={isCheck}
                       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6" />
              </TableDetail>
          )
      }


      <TableDetail isWrap={false}  extraClass={isCheck ? "bg-solitude-important" : " "} >
        <OptionalLink
            isLinked={extras.activeGroup !== ARCHIVED}
            to={getWebPathParam([CATALOG, PRODUCT, element.id, PRODUCT_DETAIL])}
            className="hover:text-gray-600"
        >
          <ProductCell isWrap={true} product={element} shotTitle={element.has_alternative ? t('app.catalog.has_alternative_product') : false}  isActiveManufacture={false} hideArchiveStatus={extras.activeGroup === ARCHIVED}/>
        </OptionalLink>
      </TableDetail>


      {
        company.product_manage_status && (
          <TableDetail>
            <StatusBarIcon status={element.status} />
          </TableDetail>
        )
      }

      <TableDetail>
        {element?.product_reference ?? '-'}
      </TableDetail>


      <TableDetail>
        {element?.manufacture_reference ?? '-'}
      </TableDetail>


      <TableDetail>
        <NumberFormat
            value={element?.default_dimension?.volume ?? 0}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={4}
            suffix={' m³'}
        />
      </TableDetail>

      <TableDetail>
        <NumberFormat
            value={element?.default_dimension?.qty_per_packaging ?? 0}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            suffix={element.unit_value ? ' '+element.unit_value.symbol : ''}
        />
      </TableDetail>

      <TableDetail>
        {element?.country_origin?.name ?? '-'}
      </TableDetail>


      <TableDetail>
        {element?.hs_code ?? '-'}
      </TableDetail>

      <TableDetail>
        {getCustomFormat(element.last_price_date, 'DD/MM/YYYY')}
      </TableDetail>

    



      <TableDetail extraClass={isCheck ? "bg-solitude-important" : ""}>
        {extras.canView && extras.activeGroup !== ARCHIVED ? (
          <ActionsMenu classExtra="right-20 -top-5">

              <Link to={getWebPathParam([CATALOG, PRODUCT, element.id, PRODUCT_DETAIL])}>
                {t("app.common.edit")}
              </Link>

              {(extras.canCreate) && (
                <button className={'text-left'} onClick={() => {
                  extras.onClone(true, element);
                }}
                >
                  {t("app.common.clone")}
                </button>
              )}

              {(extras.canDelete && element.is_removable) && (
                  <button className={'text-left'} onClick={() => {
                    extras.onRemove(true, removeDetail);
                  }}
                  >
                    {t("app.common.remove")}
                  </button>
              )}


              {extras.canArchive && (
                  <button className={'text-left'} onClick={() => {
                    extras.onRemove(true, archiveDetail);
                  }}
                  >
                    {t("app.customers.archive")}
                  </button>
              )}

            </ActionsMenu>
        ) : null}


        {extras.canDelete && extras.activeGroup === ARCHIVED ? (
          <ActionsMenu classExtra="right-20 -top-5">
              <button className={'text-left'} onClick={() => {

                const restoreDetail = {
                  title: t("app.products.restore_title"),
                  message: t("app.products.restore_description"),
                  endpoint: getPathParam([COMPANY, loggedCompany.id, PRODUCT, element.id, RESTORE])
                };

                extras.onRestore(true, restoreDetail);
              }}
              >
                {t("app.products.restore")}
              </button>
            </ActionsMenu>
        ) : null}



      </TableDetail>
    </tr>
  );
};

export default Products;
