import React, {useEffect} from "react";
import PageHeaderMenu from "../../../../components/layout/page-header-menu";
import {
    COMPANY,
    CONFIGURATION,
    IMPORT_MODELS,
    CONNECTION_CATEGORIES,
    WAREHOUSE,
    EMAIL_INTEGRATION,
    COMPANY_PREFERENCES,
    WEBHOOKS,
    COMPANY_PERMISSION, DOCUMENT_TEMPLATE,
    EMAIL_TEMPLATE,
    COMPANY_MEDIA
} from "../../../endpoints";
import {useLocation, Outlet, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getWebPathParam } from "../../../../utils/converter";
import {
    AtSymbolIcon,
    CloudUploadIcon, DocumentIcon,
    LockClosedIcon,
    MailIcon,
    QrcodeIcon,
    TerminalIcon,
    UserGroupIcon
} from "@heroicons/react/outline";
import {
    permissionGroup,
    permissionSpecific
} from "../../../../constants/permissions";
import WarehouseIcon from "../../../../resources/icons/WarehouseIcon";
import {useSelector} from "react-redux";
import BaseRestricted from "../../../../components/partials/restricted/base-restricted";
import PanelNavigation from "../../../../components/partials/panel/panel-navigation";
import {hasPermissionFor} from "../../../../components/partials/restricted/base-permission-wrapper";
import FileTextIcon from "../../../../resources/icons/FileTextIcon";

const CompanyConfiguration = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  let navigate = useNavigate();

  const { permissions } = useSelector((s) => s.userCompany);

  const isActivePermissions = permissions.isSuperAdmin;
  const isActiveWhereHouse = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_WAREHOUSE);
  const isActiveImportModel = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_IMPORT_MODEL);
  const isActiveEmailIntegration = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_EMAIL_INTEGRATION);
  const isActiveJobTypes = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_JOB_TYPES);
  const isActiveDocumentNumbering = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_DOCUMENT_NUMBERING);
  const isActiveWebHook = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_WEBHOOK);
  const isActiveDocumentTemplate = hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_DOCUMENT_TEMPLATE);
  const isActiveEmailTemplate = false; //hasPermissionFor(permissions, permissionGroup.COMPANY_CONFIGURATION, permissionSpecific.COMPANY_CONF_EMAIL_TEMPLATE);

    useEffect(() => {
        if(pathname === COMPANY + CONFIGURATION){
            if(isActivePermissions){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_PERMISSION]));
            }else if(isActiveWhereHouse){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, WAREHOUSE]));
            }else if(isActiveImportModel){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, IMPORT_MODELS]));
            }else if(isActiveEmailIntegration){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION]));
            } else if (isActiveEmailTemplate) {
                navigate(getWebPathParam([COMPANY, CONFIGURATION, EMAIL_TEMPLATE]));
            }else if(isActiveJobTypes){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, CONNECTION_CATEGORIES]));
            }else if(isActiveDocumentNumbering){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_PREFERENCES]));
            }else if(isActiveWebHook){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, WEBHOOKS]));
            }else if(isActiveDocumentTemplate){
                navigate(getWebPathParam([COMPANY, CONFIGURATION, DOCUMENT_TEMPLATE]));
            }else{
                navigate(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_MEDIA]));
            }
        }

        // eslint-disable-next-line
    }, [pathname]);



  const navigation = [
      {
          name: t("app.permissions.media_title"),
          sub_description: t("app.permissions.media_description"),
          location: getWebPathParam([COMPANY, CONFIGURATION, COMPANY_MEDIA]),
          icon: FileTextIcon,
          extraClass: 'stroke-current stroke-1',
          current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_MEDIA])),
      },
      isActivePermissions && {
            name: t("app.permissions.category_title"),
            sub_description: t("app.permissions.category_description"),
            location: getWebPathParam([COMPANY, CONFIGURATION, COMPANY_PERMISSION]),
            icon: LockClosedIcon,
            extraClass: 'stroke-current stroke-1',
            current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_PERMISSION])),
        },
        (isActiveWhereHouse) && {
            name: t("app.warehouse.warehouse"),
            sub_description: t("app.warehouse.warehouse_description"),
            location: getWebPathParam([COMPANY, CONFIGURATION, WAREHOUSE]),
            icon: WarehouseIcon,
            extraClass: 'stroke-current stroke-1',
            current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, WAREHOUSE])),
        },
        (isActiveImportModel) && {
            name: t("app.configuration.import_model"),
            sub_description: t("app.configuration.import_model_description"),
            location: getWebPathParam([COMPANY, CONFIGURATION, IMPORT_MODELS]),
            icon: CloudUploadIcon,
            current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, IMPORT_MODELS])),
        },
        (isActiveEmailIntegration) && {
            name: t("app.configuration.email_integration"),
            sub_description: t("app.configuration.email_integration_description"),
            location: getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION]),
            icon: AtSymbolIcon,
            current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, EMAIL_INTEGRATION])),
        },
        (isActiveEmailTemplate) && {
            name: t("app.configuration.email_template"),
            sub_description: t("app.configuration.email_template_description"),
            location: getWebPathParam([COMPANY, CONFIGURATION, EMAIL_TEMPLATE]),
            icon: MailIcon,
            current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, EMAIL_TEMPLATE])),
        },
        (isActiveDocumentTemplate) && {
            name: t("app.permissions.company_configuration_document_template"),
            sub_description: t("app.permissions.company_configuration_document_template_description"),
            location: getWebPathParam([COMPANY, CONFIGURATION, DOCUMENT_TEMPLATE]),
            icon: DocumentIcon,
            current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, DOCUMENT_TEMPLATE])),
        },
        (isActiveJobTypes) && {
            name: t("app.connection_category.connection_categories"),
            sub_description: t("app.connection_category.connection_categories_description"),
            hasAdminAccess: false,
            location: getWebPathParam([COMPANY, CONFIGURATION, CONNECTION_CATEGORIES]),
            icon: UserGroupIcon,
            current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, CONNECTION_CATEGORIES])),
      },
      isActiveDocumentNumbering && {
          name: t("app.company.prefix_title"),
          sub_description: t("app.company.prefix_description"),
          hasAdminAccess: true,
          location: getWebPathParam([COMPANY, CONFIGURATION, COMPANY_PREFERENCES]),
          icon: QrcodeIcon,
          current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, COMPANY_PREFERENCES])),
      },
      isActiveWebHook && {
          name: t("app.company.webhook_title"),
          sub_description: t("app.company.webhook_description"),
          hasAdminAccess: true,
          location: getWebPathParam([COMPANY, CONFIGURATION, WEBHOOKS]),
          icon: TerminalIcon,
          current: pathname.includes(getWebPathParam([COMPANY, CONFIGURATION, WEBHOOKS])),
      }

  ].filter(Boolean);

  return (

      navigation.length > 0 ? (
          <div className="flex h-full">

              <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

                  <div className="relative z-0 flex flex-1 overflow-hidden">
                      <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                          {/* Start main area*/}
                          <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                              <div className="max-w-7xl mx-auto mb-3">
                                  <PageHeaderMenu  title={navigation?.find(r => r.current)?.name ?? ''}  description={navigation?.find(r => r.current)?.sub_description ?? ''} navigation={navigation} />
                              </div>
                              <div className="max-w-7xl mx-auto">
                                  <Outlet />
                              </div>
                          </div>
                          {/* End main area */}
                      </main>
                      <aside className="relative hidden w-80 flex-shrink-0 overflow-y-auto border-r bg-white border-gray-200 lg:order-first lg:flex lg:flex-col">
                          {/* Start secondary column (hidden on smaller screens) */}
                          <div className="absolute inset-0 ">
                              <PanelNavigation isCenterTitle={true} navigation={navigation} title={t("app.menu.config")} />
                          </div>
                          {/* End secondary column */}
                      </aside>
                  </div>
              </div>
          </div>

      ) : (
          <BaseRestricted />
      )


  );
};

export default CompanyConfiguration;
