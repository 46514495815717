import {Menu, Popover, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import React, {Fragment, useState, useEffect} from "react";
import {
    CalendarIcon,
    DocumentIcon,
    FilterIcon, MapIcon, TrashIcon,
    UserIcon,
    UsersIcon,
    XIcon
} from "@heroicons/react/outline";
import LoaderWrapper from "../loader/loader-wrapper";
import {Controller, useForm} from "react-hook-form";
import InputRadioSimple from "../form/input-radio-simple";
import InputText from "../form/input-text";
import {useLocation, useNavigate} from "react-router-dom";
import {concatenateQueryStrings, getPathParam, serializeArray} from "../../../utils/converter";
import {useTranslation} from "react-i18next";
import {
    findObjectWithIdKey,
    generateParams,
    getActiveKeys,
    getFinalParams
} from "../../../constants/dynamic-filter";
import {useDispatch, useSelector} from "react-redux";
import CustomerIcon from "../../../resources/icons/CustomerIcon";
import InputDate from "../form/input-date";
import moment from "moment";
import InputSelectSearch from "../form/input-select-search";
import InputSelectSingle from "../form/input-select-single";
import InputCustomerSelect from "../form/input-customer-select";
import InputUserSelect from "../form/input-user-select";
import {COMPANY} from "../../../routes/endpoints";
import {
    BRANDS,
    CATEGORIES,
    COMPANY_FILTERS,
    COMPANY_SUPPLIERS,
    MANUFACTURES
} from "../../../api/endpoints";
import DynamicSearchList from "../../partials/common/dynamic-search-list";
import {fetchAllCustomers, fetchCustomers, fetchSuppliers} from "../../../reducers/connectionReducer";
import {TrashButton} from "../../layout/page-header";
import ConfirmPopup from "../popup/confirm-popup";
import {refreshFilters} from "../../../reducers/userCompanyReducer";
import SaveIcon from "../../../resources/icons/SaveIcon";
import InputUsersSelect from "../form/input-users-select";
import InputAllCustomerSelect from "../form/input-all-customer-select";
import InputCitiesSelect from "../form/input-cities-select";
import InputPortsSelect from "../form/input-ports-select";
import InputRegionsSelect from "../form/input-regions-select";
import InputSupplierSelect from "../form/input-supplier-select";
import WarehouseIcon from "../../../resources/icons/WarehouseIcon";
import ShippingIcon from "../../../resources/icons/ShippingIcon";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DynamicFilterMenu({filters, isLoading, onChange, defaultParams = false, lastCompanyType = '', isActiveHeaderParams = true, filterType = false, onFinalUrl = false, isActiveUrlParams = false, urlDataFields = null}) {


    const location = useLocation();
    let navigate = useNavigate();
    const { t } = useTranslation();

    const { companyType, loggedCompany } = useSelector((state) => state.user);
    const {  user_filters } = useSelector((s) => s.userCompany);
    const dispatch = useDispatch();

    const [activeFilters, setActiveFilters] = useState([]);
    const [params, setParams] = useState([]);
    const [openFilterBox, setOpenFilterBox] = useState(false)

    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [confirmDetail, setConfirmDetail] = useState({});
    const [urlData, setUrlData] = useState(isActiveUrlParams ? urlDataFields : (user_filters?.find(r => r.filter_type === filterType && r.company_type === companyType)?.filter ?? null));


    useEffect(() => {
        if(onFinalUrl && urlData){
            navigate(location.pathname + urlData);
            onFinalUrl(urlData);
        }
        // eslint-disable-next-line
    }, [urlData, navigate]);


    useEffect(() => {
        if((companyType !== lastCompanyType) && (lastCompanyType !== '')){
             setActiveFilters([]);
             setParams([]);
             setUrlData((user_filters?.find(r => r.filter_type === filterType && r.company_type === companyType)?.filter ?? null));
             navigate(location.pathname);
        }
        // eslint-disable-next-line
    }, [companyType]);


    const checkSearch = () => {
        const querySearch = new URLSearchParams(window.location.search).get(
            "querySearch"
        );
        if (querySearch) {
            return querySearch;
        }
        return false;
    }

    const handleRemove = (filter) => {
        setActiveFilters(activeFilters.filter(r => r.filter_name !== filter.filter_name));
        let page = {page: 1}; 

        if (checkSearch()){
            page['querySearch'] = checkSearch();
        }


        navigate(location.pathname + concatenateQueryStrings(page, serializeArray(params.filter(r => r[findObjectWithIdKey(r)] !== filter.filter_name))));
        setUrlData(concatenateQueryStrings(page, serializeArray(params.filter(r => r[findObjectWithIdKey(r)] !== filter.filter_name))));
        setParams(params.filter(r => r[findObjectWithIdKey(r)] !== filter.filter_name));
        setOpenFilterBox(false);
        onChange(getFinalParams(params.filter(r => r[findObjectWithIdKey(r)] !== filter.filter_name)));
    }

    const handleParams = (new_param) => {
        let page = {page: 1};

        if (checkSearch()) {
            page['querySearch'] = checkSearch();
        }

        if(isActiveHeaderParams){
            navigate(location.pathname + concatenateQueryStrings(page, serializeArray([...params.filter(r => r[findObjectWithIdKey(r)] !== new_param[findObjectWithIdKey(new_param)]), new_param]))) ;
            setUrlData(concatenateQueryStrings(page, serializeArray([...params.filter(r => r[findObjectWithIdKey(r)] !== new_param[findObjectWithIdKey(new_param)]), new_param])));
        }

        if(isActiveUrlParams){
            setUrlData(concatenateQueryStrings(page, serializeArray([...params.filter(r => r[findObjectWithIdKey(r)] !== new_param[findObjectWithIdKey(new_param)]), new_param])));
        }

        setParams([...params.filter(r => r[findObjectWithIdKey(r)] !== new_param[findObjectWithIdKey(new_param)]), new_param]);
        setOpenFilterBox(false);
        onChange(getFinalParams([...params.filter(r => r[findObjectWithIdKey(r)] !== new_param[findObjectWithIdKey(new_param)]), new_param]));
    }


    const filteredFilters = filters ? filters.filter(filter => {
        return !activeFilters.some(activeFilter => activeFilter.filter_name === filter.filter_name);
    }) : [];


    useEffect(() => {
        if(isLoading === false && location.search && activeFilters.length === 0){
            let activeFilters = filteredFilters.filter(item => getActiveKeys(location.search).includes(item.filter_name));
            if(activeFilters.length > 0){
                setActiveFilters(activeFilters);
                setParams(generateParams(activeFilters, location.search));
            }
        }

        if(isLoading === false && defaultParams && activeFilters.length === 0){
            let activeFilters = filteredFilters.filter(item => getActiveKeys(defaultParams).includes(item.filter_name));
            if(activeFilters.length > 0){
                setActiveFilters(activeFilters);
                setParams(generateParams(activeFilters, defaultParams));
            }
        }
        // eslint-disable-next-line
    }, [filteredFilters]);

    return (
        <>

            {
                activeFilters.map((filter) => (
                    <Fragment key={filter.filter_name}>
                        {
                            filter.type === 'string' && (
                                <FilterByString
                                    filter={filter}
                                    onRemoveItem={() => {handleRemove(filter)}}
                                    onSave={(r) => {handleParams(r)}}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                 />
                            )
                        }

                        {
                            filter.type === 'date' && (
                                <FilterByDate
                                    filter={filter}
                                    onRemoveItem={() => {handleRemove(filter)}}
                                    onSave={(r) => {handleParams(r)}}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                 />
                            )
                        }
                        {
                            (filter.type === 'dateList' || filter.type === 'genericList') && (
                                <FilterByGenericList
                                    filter={filter}
                                    onRemoveItem={() => {handleRemove(filter)}}
                                    onSave={(r) => {handleParams(r)}}
                                    params={params}
                                    defaultParams={defaultParams}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                 />
                            )
                        }
                        {
                            (filter.type === 'userList') && (
                                <FilterByUserList
                                    filter={filter}
                                    onRemoveItem={() => {handleRemove(filter)}}
                                    onSave={(r) => {handleParams(r)}}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }
                        {
                            (filter.type === 'usersList') && (
                                <FilterByUsersList
                                    filter={filter}
                                    onRemoveItem={() => {handleRemove(filter)}}
                                    onSave={(r) => {handleParams(r)}}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }


                        {
                            (filter.type === 'citiesList') && (
                                <FilterByCitiesList
                                    filter={filter}
                                    onRemoveItem={() => { handleRemove(filter) }}
                                    onSave={(r) => { handleParams(r) }}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }


                        {
                            (filter.type === 'regionsList') && (
                                <FilterByRegionsList
                                    filter={filter}
                                    onRemoveItem={() => { handleRemove(filter) }}
                                    onSave={(r) => { handleParams(r) }}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }

                        {
                            (filter.type === 'portList') && (
                                <FilterByPortsList
                                    filter={filter}
                                    onRemoveItem={() => { handleRemove(filter) }}
                                    onSave={(r) => { handleParams(r) }}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }
                        
                        {
                            (filter.type === 'customerList') && (
                                <FilterByCustomerList
                                    filter={filter}
                                    onRemoveItem={() => {handleRemove(filter)}}
                                    onSave={(r) => {handleParams(r)}}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }

                        {
                            (filter.type === 'supplierList') && (
                                <FilterBySupplierList
                                    filter={filter}
                                    onRemoveItem={() => { handleRemove(filter) }}
                                    onSave={(r) => { handleParams(r) }}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }

                        {
                            (filter.type === 'allCustomerList') && (
                                <FilterByAllCustomerList
                                    filter={filter}
                                    onRemoveItem={() => { handleRemove(filter) }}
                                    onSave={(r) => { handleParams(r) }}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }


                        
                        {
                            (filter.type === 'queryList') && (
                                <FilterByQueryList
                                    filter={filter}
                                    onRemoveItem={() => {handleRemove(filter)}}
                                    onSave={(r) => {handleParams(r)}}
                                    params={params}
                                    openFilterBox={openFilterBox}
                                    activeFilter={getActiveKeys(location.search ? location.search : defaultParams).includes(filter.filter_name) ? filter.filter_name : null}
                                />
                            )
                        }
                    </Fragment>
                ))
            }


            {
                openFilterBox === false && !isLoading && ((filteredFilters.length > 0) || ((activeFilters.length > 0 && filterType) && ((user_filters?.find(r => r.filter_type === filterType && r.company_type === companyType)?.filter ?? '') !== urlData))) && (
                    <Menu as="div" className="relative inline-block text-left z-10">
                        <div>
                            <div className="btn border-solitude-400  text-blue-1000" >
                                <div className="flex justify-between items-center ">


                                    {
                                        filteredFilters.length > 0 && (
                                            <Menu.Button className={'mr-4 flex justify-between items-center'}>
                                                <FilterIcon className="w-5 h-5 mr-5 text-violet-200 hover:text-violet-100" aria-hidden="true"/>
                                                {t("filter.filter_title")}
                                                <ChevronDownIcon className="w-5 h-5 ml-4 -mr-1 text-violet-200 hover:text-violet-100" aria-hidden="true"/>
                                            </Menu.Button>
                                        )
                                    }

                                    {
                                        (activeFilters.length > 0 && filterType) && ((user_filters?.find(r => r.filter_type === filterType && r.company_type === companyType)?.filter ?? '') !== urlData) && (
                                            <div className="border-l cursor-pointer"  onClick={() => {
                                                const restoreDetail = {
                                                    title: t("app.common.save_filter_title"),
                                                    message: t("app.common.save_filter_description"),
                                                    endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_FILTERS]),
                                                    endpointData: {filter_type: filterType, company_type: companyType, filter: urlData}
                                                };
                                                setConfirmDetail(restoreDetail);
                                                setOpenConfirm(true);
                                            }}>
                                                <SaveIcon
                                                    className={'text-blue-1000 stroke-current group-hover:text-solitude-200 w-4 h-4 ml-2'}

                                                    aria-hidden="true"
                                                />
                                            </div>
                                        )
                                    }

                                </div>

                            </div>




                        </div>

                        {
                            filteredFilters.length > 0   && (
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute  w-56 mt-2 origin-top-right bg-solitude-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <LoaderWrapper isLoading={isLoading}>

                                            {filteredFilters.map((item, index) => (
                                                <div className="px-1  " key={index}>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <div
                                                                onClick={() => {
                                                                    setActiveFilters([...activeFilters, item]);
                                                                    setOpenFilterBox(item.filter_name);
                                                                }}
                                                                className={`${
                                                                    active ? 'bg-blue-1000 text-solitude-200' : 'bg-solitude-200'
                                                                } group flex rounded-md items-center w-full px-2 py-2 text-sm cursor-pointer`}
                                                            >
                                                                {
                                                                    <DynamicIcon
                                                                        type={item.type}
                                                                        className={classNames(
                                                                            active
                                                                                ? 'text-solitude-200'
                                                                                : 'text-blue-1000 group-hover:text-solitude-200',
                                                                            'w-5 h-5 mr-2'
                                                                        )}
                                                                    />
                                                                }

                                                                {t("filter.title."+item.filter_name+"_label")}

                                                            </div>
                                                        )}
                                                    </Menu.Item>
                                                </div>

                                            ))}

                                        </LoaderWrapper>
                                    </Menu.Items>
                                </Transition>


                            )
                        }
                    </Menu>
                )
            }






            {
                (filterType && (user_filters?.find(r => r.filter_type === filterType && r.company_type === companyType)?.filter ?? '')) && openFilterBox === false && !isLoading && (
                    <TrashButton
                        icon={
                            <TrashIcon
                                className="w-4 h-4 flex-shrink-0"
                                aria-hidden="true"
                            />
                        }
                        onClick={() => {
                            const restoreDetail = {
                                title: t("app.common.remove_filter_title"),
                                message: t("app.common.remove_filter_description"),
                                endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_FILTERS]),
                                endpointData: {filter_type: filterType, company_type: companyType,  filter: null}
                            };
                            setConfirmDetail(restoreDetail);
                            setOpenConfirm(true);
                        }}
                        text={''}
                    />
                )
            }


            {isOpenConfirm && (
                <ConfirmPopup
                    isOpen={isOpenConfirm}
                    setIsOpen={setOpenConfirm}
                    detail={confirmDetail}
                    setDetail={(r) => {
                        dispatch(refreshFilters({ company_id: loggedCompany.id }));
                    }}
                />
            )}

        </>
    )
}


const DynamicIcon = ({type, className}) => {
    switch(type) {
        case "date":
            return <CalendarIcon className={className} aria-hidden="true"  />;
        case "dateList":
            return <CalendarIcon className={className} aria-hidden="true" />;
        case "string":
            return <DocumentIcon className={className} aria-hidden="true"  />;
        case "userList":
            return <UserIcon className={className} aria-hidden="true"  />;
        case "customerList":
            return <CustomerIcon className={className} aria-hidden="true"  />;
        case "supplierList":
            return <WarehouseIcon className={className} aria-hidden="true" />;
        case "usersList":
            return <UsersIcon className={className} aria-hidden="true" />;
        case "portList":
            return <ShippingIcon className={className} aria-hidden="true" />;
        case "citiesList":
            return <MapIcon className={className} aria-hidden="true" />;
        case "regionsList":
            return <MapIcon className={className} aria-hidden="true" />;
        default:
            return <FilterIcon   className={className} aria-hidden="true"  />;
    }
}

const FilterByString = ({filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false}) => {

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
      data[filter.filter_id] = filter.filter_name;
      onSave(data);
    };


    useEffect(() => {
        if(activeFilter && location.search){
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if(param){
                setValue(filter.condition_name, param[filter.condition_name]);
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, param[filter.filter_name]);
            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);


    let title = t("filter.title."+filter.filter_name+"_label");
    let extra = watch(filter.filter_name) ? ': '+ watch(filter.filter_name) : '';


    return (
        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">



                        {
                            filter.condition.split("|").map((c, index) => (
                                <InputRadioSimple
                                    key={index}
                                    name={filter.condition_name}
                                    errors={errors[filter.condition_name]}
                                    input={{...register(filter.condition_name, { required: true })}}
                                    label={t("filter.condition."+c)}
                                    value={c}
                                />
                            ))
                        }




                        <div className="col-span-2">
                            <InputText
                                id={filter.filter_name}
                                type={"text"}
                                errors={errors[filter.filter_name]}
                                input={{ ...register(filter.filter_name, { required: true }) }}
                                label={t("filter.title."+filter.filter_name+"_label")}
                                isShowLabel={false}
                            />
                        </div>




                    </div>


                    <div className="flex before:flex-1  items-center justify-between mt-6">
                        <div>
                            <CancelBtn onRemoveItem={onRemoveItem} />
                            <SubmitBtn />
                        </div>
                    </div>

                </div>

            </form>

        </PopoverForm>
    )
}

const FilterByDate = ({filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false }) => {

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();


    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.filter_name] = moment(data[filter.filter_name]).format("YYYY-MM-D");
        if(watch(filter.condition_name) === 'between'){
            data[filter.filter_name+'Second'] = moment(data[filter.filter_name+'Second']).format("YYYY-MM-D");
        }else{
            delete data[filter.filter_name+'Second'];
        }
        onSave(data);
    };


    useEffect(() => {
        if(activeFilter && location.search){
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if(param){
                setValue(filter.condition_name, param[filter.condition_name]);
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, moment(param[filter.filter_name]).format("YYYY-MM-D"));
                if(param[filter.filter_name+'Second']){
                    setValue(filter.filter_name+'Second', moment(param[filter.filter_name+'Second']).format("YYYY-MM-D"));
                }
            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);


    return (
        <PopoverForm label={t("filter.title."+filter.filter_name+"_label")} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">



                        {
                            filter.condition.split("|").map((c, index) => (
                                <InputRadioSimple
                                    key={index}
                                    name={filter.condition_name}
                                    errors={errors[filter.condition_name]}
                                    input={{...register(filter.condition_name, { required: true })}}
                                    label={t("filter.condition."+c)}
                                    value={c}
                                />
                            ))
                        }




                        <div className="col-span-2 relative">
                            <Controller
                                name={filter.filter_name}
                                rules={{ required: true }}
                                control={control}
                                render={({
                                             field: { onChange, value, name },
                                             fieldState: { error },
                                         }) => (
                                    <InputDate
                                        label={watch(filter.condition_name) === 'between' ? t("filter.general.date_from") : t("filter.general.date")}
                                        extraClass={'filter'}
                                        format={"YYYY-MM-D HH:mm:ss"}
                                        placeholder={"Es. 01/12/2021"}
                                        activeMinDate={false}
                                        startDate={value}
                                        disabled={false}
                                        isShowTime={false}
                                        errors={error}
                                        name={name}
                                        onChange={(e) => {
                                            if(watch(filter.condition_name) === 'between'){
                                                setValue(filter.filter_name+'Second', e);
                                            }
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </div>

                        {
                            watch(filter.condition_name) === 'between' && (
                                <div className="col-span-2 relative mt-2">
                                    <Controller
                                        name={filter.filter_name+'Second'}
                                        rules={{ required: true }}
                                        control={control}
                                        render={({
                                                     field: { onChange, value, name },
                                                     fieldState: { error },
                                                 }) => (
                                            <InputDate
                                                label={t("filter.general.date_to")}
                                                extraClass={'filter'}
                                                format={"YYYY-MM-D HH:mm:ss"}
                                                placeholder={"Es. 01/12/2021"}
                                                activeMinDate={true}
                                                selectsEnd={true}
                                                minDate={new Date(moment(watch(filter.filter_name)).format('MM/DD/YYYY'))}
                                                startDate={value}
                                                disabled={!watch(filter.filter_name)}
                                                isShowTime={false}
                                                errors={error}
                                                name={name}
                                                onChange={(e) => {
                                                    onChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            )
                        }
                    </div>


                    <div className="flex before:flex-1  items-center justify-between mt-6">
                        <div>
                            <CancelBtn onRemoveItem={onRemoveItem}/>
                            <SubmitBtn />
                        </div>
                    </div>

                </div>

            </form>

        </PopoverForm>
    )
}

const FilterByGenericList = ({filter, onRemoveItem, onSave, activeFilter, params, defaultParams = false, openFilterBox = false}) => {

    const {
        watch,
        handleSubmit,
        setValue,
        control
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.filter_name] = filter.is_multi ? data[filter.filter_name].join("-") :data[filter.filter_name] ;
        onSave(data);
    };


    useEffect(() => {
        if(activeFilter && (location.search || defaultParams)){
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if(param){
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, filter.is_multi ? param[filter.filter_name].split("-").map((item) => {
                    return item
                }) : param[filter.filter_name]);
            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);


    let title = t("filter.title."+filter.filter_name+"_label");
    let extra = (!filter.is_multi && watch(filter.filter_name)) ? ': '+ (t('filter.general.'+watch(filter.filter_name))) : '';
    if(filter.is_multi && (watch(filter.filter_name) ?? []).length > 0){
        extra = ': '+ (watch(filter.filter_name).length > 1 ? t("app.common.selected_filter", {number: watch(filter.filter_name).length}) : t('filter.general.'+watch(filter.filter_name)[0])) ;
    }

    return (

        <PopoverForm label={title+extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2 z-50">
                            <Controller
                                name={filter.filter_name}
                                rules={{ required: false }}
                                control={control}
                                render={({
                                             field: { onChange, value, name },
                                             fieldState: { error },
                                         }) => (

                                    filter.is_multi ? (
                                        <InputSelectSearch
                                            label={t("filter.title."+filter.filter_name+"_label")}
                                            name={name}
                                            translationPattern={'filter.general.'}
                                            onChangeData={(e) => {
                                                onChange(e);
                                            }}
                                            options={filter.condition.split("|").map(e => (e))}
                                            values={value}
                                            errors={error}
                                        />
                                    ) : (
                                        <InputSelectSingle
                                            label={t("filter.title."+filter.filter_name+"_label")}
                                            name={name}
                                            translationPattern={'filter.general.'}
                                            onChangeData={(e) => {
                                                onChange(e);
                                            }}
                                            options={filter.condition.split("|").map(e => (e))}
                                            valueData={value}
                                            errors={error}
                                        />
                                    )


                                )}
                            />
                        </div>

                    </div>


                    <div className="flex before:flex-1  items-center justify-between mt-6">
                        <div>
                            <CancelBtn onRemoveItem={onRemoveItem}/>
                            <SubmitBtn />
                        </div>
                    </div>

                </div>

            </form>
        </PopoverForm>
    )
}

const FilterByUserList = ({filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false}) => {


    const [selected, setSelected] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };


    useEffect(() => {
        if(activeFilter && location.search){
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if(param){
                setValue(filter.condition_name, param[filter.condition_name]);
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, param[filter.filter_name]);
            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);

    let title = t("filter.title."+filter.filter_name+"_label");
    let extra = selected ? (': '+selected) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">
                            <InputUserSelect
                                label={t("filter.title."+filter.filter_name+"_label")}
                                placeholder={''}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(person) => {
                                    setValue(filter.filter_name, person.id);
                                    setSelected(person.fullname);
                                }}
                                onSelected={(person) => {
                                    if(person){
                                        setSelected(person.fullname);
                                    }
                                }}
                                setLoaded={(r) => {
                                    setLoading(r)
                                }}
                            />
                        </div>

                    </div>


                    {
                        !isLoading && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem}/>
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}

const FilterByUsersList = ({filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false}) => {


    const [selected, setSelected] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.filter_name] = selectedUsers.map(u => u.id).join(",") ;
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };



    useEffect(() => {
        if(activeFilter && location.search){
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if(param){
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.condition_name, param[filter.condition_name]);
                setSelected(param[filter.filter_name].split(",").length);
                if(param[filter.filter_name].split(",").length > 0 ){
                    setSelectedUsers(param[filter.filter_name].split(",").map((item) => {
                        return {id: item};
                    }));
                }



            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);



    let title = t("filter.title."+filter.filter_name+"_label");
    let extra = selected > 0 ? (': '+(selected > 1 ? t("filter.title.selected_users", {value: selected}): t("filter.title.selected_user", {value: selected}))) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">
                            <InputUsersSelect
                                label={t("filter.title."+filter.filter_name+"_label")}
                                placeholder={''}
                                multiple={true}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(person) => {
                                    setSelectedUsers(person);
                                    setSelected(person.length);

                                }}
                                selectedUsers={selectedUsers}

                            />
                        </div>

                    </div>


                    {
                        (selectedUsers.length > 0 && (selectedUsers[0]?.fullname ?? false)) && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem}/>
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}

const FilterByCitiesList = ({ filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false }) => {


    const [selected, setSelected] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.filter_name] = selectedData.map(u => u.id).join(",");
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };



    useEffect(() => {
        if (activeFilter && location.search) {
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if (param) {
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.condition_name, param[filter.condition_name]);
                setSelected(param[filter.filter_name].split(",").length);
                if (param[filter.filter_name].split(",").length > 0) {
                    setSelectedData(param[filter.filter_name].split(",").map((item) => {
                        return { id: item };
                    }));
                }



            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);

    
    

    let title = t("filter.title." + filter.filter_name + "_label");
    let extra = selected > 0 ? (': ' + (selected > 1 ? t("filter.title.selected_cities", { value: selected }) : t("filter.title.selected_city", { value: selected }))) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">
                            <InputCitiesSelect
                                label={t("filter.title." + filter.filter_name + "_label")}
                                placeholder={''}
                                multiple={true}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(data) => {
                                    setSelectedData(data);
                                    setSelected(data.length);

                                }}
                                selectedData={selectedData}

                            />
                        </div>

                    </div>


                    {
                        (selectedData.length > 0 && (selectedData[0]?.label ?? false)) && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem} />
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}

const FilterByRegionsList = ({ filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false }) => {


    const [selected, setSelected] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.filter_name] = selectedData.map(u => u.id).join(",");
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };



    useEffect(() => {
        if (activeFilter && location.search) {
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if (param) {
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.condition_name, param[filter.condition_name]);
                setSelected(param[filter.filter_name].split(",").length);
                if (param[filter.filter_name].split(",").length > 0) {
                    setSelectedData(param[filter.filter_name].split(",").map((item) => {
                        return { id: item };
                    }));
                }



            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);


    
    

    let title = t("filter.title." + filter.filter_name + "_label");
    let extra = selected > 0 ? (': ' + (selected > 1 ? t("filter.title.selected_regions", { value: selected }) : t("filter.title.selected_region", { value: selected }))) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">
                            <InputRegionsSelect
                                label={t("filter.title." + filter.filter_name + "_label")}
                                placeholder={''}
                                multiple={true}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(data) => {
                                    setSelectedData(data);
                                    setSelected(data.length);

                                }}
                                selectedData={selectedData}

                            />
                        </div>

                    </div>


                    {
                        (selectedData.length > 0 && (selectedData[0]?.label ?? false)) && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem} />
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}

const FilterByPortsList = ({ filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false }) => {


    const [selected, setSelected] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.filter_name] = selectedData.map(u => u.id).join(",");
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };



    useEffect(() => {
        if (activeFilter && location.search) {
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if (param) {
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.condition_name, param[filter.condition_name]);
                setSelected(param[filter.filter_name].split(",").length);
                if (param[filter.filter_name].split(",").length > 0) {
                    setSelectedData(param[filter.filter_name].split(",").map((item) => {
                        return { id: item };
                    }));
                }



            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);




    let title = t("filter.title." + filter.filter_name + "_label");
    let extra = selected > 0 ? (': ' + (selected > 1 ? t("filter.title.selected_ports", { value: selected }) : t("filter.title.selected_port", { value: selected }))) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">
                            <InputPortsSelect
                                label={t("filter.title." + filter.filter_name + "_label")}
                                placeholder={''}
                                multiple={true}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(data) => {
                                    setSelectedData(data);
                                    setSelected(data.length);

                                }}
                                selectedData={selectedData}

                            />
                        </div>

                    </div>


                    {
                        (selectedData.length > 0 && (selectedData[0]?.label ?? false)) && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem} />
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}


const FilterByCustomerList = ({filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false}) => {


    const { loggedCompany } = useSelector((state) => state.user);
    const {customers, isLoadingCustomers} = useSelector((state) => state.connection);


    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };


    useEffect(() => {
        if(activeFilter && location.search){
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if(param){
                setValue(filter.condition_name, param[filter.condition_name]);
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, param[filter.filter_name]);
            }
        }
        dispatch(fetchCustomers({company_id: loggedCompany.id}));

        // eslint-disable-next-line
    }, [activeFilter, setValue, dispatch, loggedCompany]);

    let title = t("filter.title."+filter.filter_name+"_label");
    let extra = watch(filter.filter_name) ? (': '+(customers?.find(elem => elem.id === watch(filter.filter_name))?.business_name ?? '')) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">

                            <InputCustomerSelect
                                label={t("filter.title."+filter.filter_name+"_label")}
                                placeholder={''}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(person) => {
                                    setValue(filter.filter_name, person.id);
                                }}
                            />

                        </div>

                    </div>


                    {
                        !isLoadingCustomers && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem}/>
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}

const FilterBySupplierList = ({ filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false }) => {


    const { loggedCompany } = useSelector((state) => state.user);
    const { suppliers, isLoadingSuppliers } = useSelector((state) => state.connection);


    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };


    useEffect(() => {
        if (activeFilter && location.search) {
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if (param) {
                setValue(filter.condition_name, param[filter.condition_name]);
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, param[filter.filter_name]);
            }
        }
        dispatch(fetchSuppliers({ company_id: loggedCompany.id }));

        // eslint-disable-next-line
    }, [activeFilter, setValue, dispatch, loggedCompany]);

    let title = t("filter.title." + filter.filter_name + "_label");
    let extra = watch(filter.filter_name) ? (': ' + (suppliers?.find(elem => elem.id === watch(filter.filter_name))?.business_name ?? '')) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">

                            <InputSupplierSelect
                                label={t("filter.title." + filter.filter_name + "_label")}
                                placeholder={''}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(person) => {
                                    setValue(filter.filter_name, person.id);
                                }}
                            />

                        </div>

                    </div>


                    {
                        !isLoadingSuppliers && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem} />
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}



const FilterByAllCustomerList = ({ filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false }) => {


    const { loggedCompany } = useSelector((state) => state.user);
    const { allCustomers, isLoadingAllCustomers } = useSelector((state) => state.connection);


    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();

    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.condition_name] = filter.condition;
        onSave(data);
    };


    useEffect(() => {
        if (activeFilter && location.search) {
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if (param) {
                setValue(filter.condition_name, param[filter.condition_name]);
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, param[filter.filter_name]);
            }
        }
        dispatch(fetchAllCustomers({ company_id: loggedCompany.id, params: { find_all: true } }));

        // eslint-disable-next-line
    }, [activeFilter, setValue, dispatch, loggedCompany]);

    let title = t("filter.title." + filter.filter_name + "_label");
    let extra = watch(filter.filter_name) ? (': ' + (allCustomers?.find(elem => elem.id === watch(filter.filter_name))?.business_name ?? '')) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">

                            <InputAllCustomerSelect
                                label={t("filter.title." + filter.filter_name + "_label")}
                                placeholder={''}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(person) => {
                                    setValue(filter.filter_name, person.id);
                                }}
                            />

                        </div>

                    </div>


                    {
                        !isLoadingAllCustomers && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem} />
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}


const FilterByQueryList = ({filter, onRemoveItem, onSave, activeFilter, params, openFilterBox = false}) => {


    const { loggedCompany } = useSelector((state) => state.user);
    const [isLoading, setLoading] = useState(true);


    const {
        watch,
        handleSubmit,
        setValue,
    } = useForm();
    const [selected, setSelected] = useState(false);

    const location = useLocation();
    const { t } = useTranslation();


    const endpointData = [
        {
            type: 'companyBrand',
            endpoint: getPathParam([COMPANY, loggedCompany.id, BRANDS]),
            selectLabel: t("app.catalog.brands"),
            responseLabel: 'brands',
            label: 'name',
        },
        {
            type: 'manufactureList',
            endpoint: getPathParam([COMPANY, loggedCompany.id, MANUFACTURES]),
            selectLabel: t("app.catalog.manufacture"),
            responseLabel: 'manufactures',
            label: 'name'
        },
        {
            type: 'supplierList',
            endpoint: getPathParam([COMPANY, loggedCompany.id, COMPANY_SUPPLIERS]),
            selectLabel: t("app.catalog.supplier"),
            responseLabel: 'company_suppliers',
            label: 'name'
        },
        {
            type: 'categoryList',
            endpoint: getPathParam([COMPANY, loggedCompany.id, CATEGORIES]),
            selectLabel: t("app.categories.categories"),
            responseLabel: 'categories',
            label: 'name'
        }
    ];

    const filterType = endpointData.find(r => r.type === filter.filter_name);

    const onSubmit = async (data) => {
        data[filter.filter_id] = filter.filter_name;
        data[filter.condition_name] = filter.condition;
        data[filter.filter_name+'Second'] = selected;
        onSave(data);
    };

    useEffect(() => {
        if(activeFilter && location.search){
            let param = params.find(r => r[filter.filter_id] === activeFilter);
            if(param){
                setValue(filter.condition_name, param[filter.condition_name]);
                setValue(filter.filter_id, param[filter.filter_id]);
                setValue(filter.filter_name, param[filter.filter_name]);
                setSelected(decodeURIComponent(param[filter.filter_name+'Second'].replace(/\+/g, ' ')));

            }
        }
        // eslint-disable-next-line
    }, [activeFilter, setValue]);

    let title = t("filter.title."+filter.filter_name+"_label");
    let extra = selected ? (': '+selected) : '';

    return (

        <PopoverForm label={title + extra} onRemoveItem={onRemoveItem} openFilterBox={openFilterBox} filterName={filter.filter_name}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-4 p-4">
                    <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-1 sm:grid-cols-1">

                        <div className="col-span-2">

                            <DynamicSearchList
                                selectLabel={filterType.selectLabel}
                                endpoint={filterType.endpoint}
                                responseLabel={filterType.responseLabel}
                                label={filterType.label}
                                placeholder={''}
                                selectedItem={watch(filter.filter_name)}
                                onSelect={(person) => {
                                    setValue(filter.filter_name, person.id);
                                    setSelected(person.label);
                                }}
                                onSelected={(person) => {
                                    setSelected(person.label);
                                }}
                                setLoaded={(r) => {
                                    setLoading(r)
                                }}
                            />
                        </div>

                    </div>


                    {
                        !isLoading && (
                            <div className="flex before:flex-1  items-center justify-between mt-6">
                                <div>
                                    <CancelBtn onRemoveItem={onRemoveItem}/>
                                    <SubmitBtn />
                                </div>
                            </div>
                        )
                    }


                </div>

            </form>
        </PopoverForm>
    )
}


const PopoverForm = ({label, onRemoveItem, children, openFilterBox = false, filterName}) => {


    return (
        <Popover>
            {({ open }) => {
                return (
                    <>
                        <Popover.Button className="btn border-solitude-400 hover:bg-solitude-400 text-blue-1000" >
                            <div className="flex justify-between items-center ">
                                <div className={'mr-4 flex justify-between items-center'}>
                                    {label}
                                    <ChevronDownIcon className="w-5 h-5 ml-4 -mr-1 text-violet-200 hover:text-violet-100" aria-hidden="true"/>
                                </div>
                                <div className="border-l"  onClick={() => {
                                    onRemoveItem()
                                }}>
                                    <XIcon
                                        className={'text-blue-1000 group-hover:text-solitude-200 w-5 h-5 ml-2'}
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </Popover.Button>


                        {
                            openFilterBox === filterName ? (
                                <Transition
                                    as={Fragment}
                                    show={true}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className={"absolute z-20 mt-3 w-screen max-w-xs transform px-4 sm:px-0  "}>

                                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className="absolute  rounded-lg shadow-lg bg-white w-screen max-w-xs">
                                                {children}
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            ) : (
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className={"absolute z-20 mt-3 w-screen max-w-xs transform px-4 sm:px-0  "}>

                                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className="absolute  rounded-lg shadow-lg bg-white w-screen max-w-xs">
                                                {children}
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            )
                        }



                    </>
                )
            }}
        </Popover>
    )

}

const CancelBtn = ({onRemoveItem}) => {

    const { t } = useTranslation();

    return (
        <button
            type="button"
            onClick={() => {
                onRemoveItem()
            }}
            className="rounded mr-3 bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
            {t("filter.cancel_title")}
        </button>
    )

}

const SubmitBtn = () => {
    const { t } = useTranslation();
    return (
        <Popover.Button
            as={'input'}
            type="submit"
            className="rounded bg-blue-1000 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-800"
            value={t("filter.confirm_title")}
        />
    )

}
