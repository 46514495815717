import React, {Fragment, useEffect, useState} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import {ChevronDownIcon, MapIcon} from "@heroicons/react/outline";
import Loader from "../loader/loader";
import {getPorts} from "../../../api/config";
import {getPortParams} from "../../../utils/converter";


export default function InputPortSelect({ 
                                            label = '',
                                            placeholder,
                                            isRequired = false,
                                            length = 3,
                                            selectedItem = null,
                                            onSelect,
                                          }) {




    const [query, setQuery] = useState('');

    const [second, setSecond] = useState(0);
    const [startCounter, setStartCounter] = useState(false);

    const [list, setList] = useState([])
    const [isLoading, setLoading] = useState(true)


    const filteredData = list;


    async function fetchPorts(signal = null) {
        setLoading(true);
        getPorts({ params: { query: query, port_id: selectedItem?.id ?? null, force_search: query.length > 0 }, signal })
            .then((response) => {
                setList(response.length > 0 ? getPortParams(response) : []);
                setLoading(false);
            }).catch((e) => {
                //setLoading(false);
            });
       
    }


    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;


        if (selectedItem) {
            fetchPorts(signal);
        }


        return () => {
            controller.abort();
        };
        // eslint-disable-next-line
    }, [selectedItem]);


    useEffect(() => {
        const interval = setInterval(() => {
            if (startCounter) {
                setSecond(second + 1);
                if (((query.length >= length || query.length === 0) && (filteredData.length === 0 && query !== '') && second === 1)) {
                    setStartCounter(false);
                    setSecond(0);
                    fetchPorts();
                }
            }
        }, 200);

        return () => {
            clearInterval(interval);
        };
    });




    return (
        <div className="w-full">
            <Combobox value={selectedItem ? list.find(elem => elem.id === selectedItem.id) : null} onChange={(r) => {
                onSelect(r);
            }}>

                <div className="relative ">

                    {
                        label && (
                            <label className="block text-sm font-medium mb-1 text-gray-700">
                                {label} {isRequired && <span className="text-red-500">*</span>}
                            </label>
                        )
                    }
                   

                    <div className="border relative w-full cursor-default overflow-hidden rounded bg-white text-left sm:text-sm shadow-sm">
                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 capitalize"
                            placeholder={placeholder}
                            displayValue={(item) => item?.label ?? ''}
                            onChange={(event) => {
                                setQuery(event.target.value)
                                setSecond(0);
                                setStartCounter(true);
                            }}
                        />
                        {
                            list.length > 0 && (
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </Combobox.Button>
                            )
                        }
                        
                    </div>


                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">

                            {
                                isLoading ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        <Loader />
                                    </div>
                                ) : (
                                    <>
                                        {filteredData.length === 0 && query !== '' ? (
                                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                Nothing found.
                                            </div>
                                        ) : (
                                            filteredData.map((item) => (

                                                <Combobox.Option
                                                    key={item.id}
                                                    className={({ selected, active }) =>
                                                        `relative cursor-pointer select-none py-2 px-4 ${
                                                            (active || selected) ? 'bg-solitude-400 text-blue-1000' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <span className={'flex-shrink-0 inline-flex relative items-center justify-center h-10 w-10 rounded-md bg-blue-1000'}>
                                                                        <span className="font-medium leading-none text-white">
                                                                            <MapIcon className={'h-5 w-5 text-blue-100'} />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="text-sm font-medium text-blue-1000 group-hover:text-gray-900 uppercase"><b>{item?.data?.name ?? ''}</b></p>
                                                                    {
                                                                        item.data.code && (
                                                                            <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700"><b>UN/Locode:</b> {item.data.code} </p>
                                                                        )
                                                                    }
                                                                    <p className="text-xs font-medium text-gray-900 group-hover:text-gray-700">{item.data?.country?.name+', ' ?? ''} {item.data?.country?.code ?? ''}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))
                                        )}
                                    </>
                                )
                            }


                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
