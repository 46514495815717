import React, { useEffect, useState } from "react";
import { useLocation} from "react-router-dom";
import PageHeader, { ActionButton } from "../../../components/layout/page-header";
import { useDispatch, useSelector } from "react-redux";
import {BOOKINGS} from "../../endpoints";
import ShippingIcon from "../../../resources/icons/ShippingIcon";
import { getCustomFormat, getFormattedDate } from "../../../utils/timeUtils";
import {getPathParam} from "../../../utils/converter";
import CustomerCell from "../../../components/common/table/customer-cell";
import {getPermission, permissionGroup, permissionType} from "../../../constants/permissions";
import { useTranslation } from "react-i18next";
import {COMPANY} from "../../../api/endpoints";
import RemovePopup from "../../../components/common/popup/remove-popup";
import {DELIVERY, PICKUP} from "../../../constants/config";
import {subscriptionUsageLimits} from "../../../reducers/subscriptionReducer";
import {PlusIcon, TruckIcon} from "@heroicons/react/outline";
import DynamicFilterMenu from "../../../components/common/table/dynamic-filter-menu";
import {generateParamsFormLocation} from "../../../constants/dynamic-filter";
import BasePermissionWrapper from "../../../components/partials/restricted/base-permission-wrapper";
import {StatusBarIcon} from "../../../components/common/alert/status-bar-icon";
import UnderlineTab from "../../../components/partials/Tabs/underline-tab";
import AddBooking from "./add-booking";
import { getElementBody } from "../../../api/config";
import AirIcon from "../../../resources/icons/AirIcon";
import UsersToolbar from "../../../components/common/card/users-toolbar";
import UserInitial from "../../../components/common/table/user-initial";
import ManageBooking from "./manage-booking";
import TableHeader from "../../../components/common/table/table-header";
import LoaderWrapper from "../../../components/common/loader/loader-wrapper";



const Booking = () => {
  const { loggedCompany, companyType } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const location = useLocation();

  const tabs = [
    { name: t("app.fulfillment.pickup"), group: PICKUP },
    { name: t("app.fulfillment.delivery"), group: DELIVERY },
  ];
  const [activeGroup, setActiveGroup] = useState(PICKUP);




  const shippingTypes = [
    { type: 'land', name: '', icon: TruckIcon },
    { type: 'sea', name: '', icon: ShippingIcon },
    { type: 'air', name: '', icon: AirIcon },
  ];
  const [shippingType, setShippingType] = useState(shippingTypes[0])



  const [isFetchLoading, setFetchLoading] = useState(true);
  const [list, setList] = useState([]);
  // eslint-disable-next-line
  const [Rows, setRows] = useState(0);

  const [limit] = useState(30);


  const checkOffset = () => {
    const pageNumber = new URLSearchParams(window.location.search).get(
        "page"
    );

    if(pageNumber){
      return parseInt(((pageNumber * limit) - limit));
    }
    return 0;
  }

  const [offset, setOffset] = useState(checkOffset());

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenManage, setIsOpenManage] = useState(false);
  const [manageDetails, setManageDetails] = useState(false);

  const [isOpenRemove, setOpenRemove] = useState(false);
  // eslint-disable-next-line
  const [removeDetail, setRemoveDetail] = useState({});
  const [needRefresh, setNeedRefresh] = useState(false);
  // eslint-disable-next-line
  const [sortBy, setSortBy] = useState({accessor: null, type: null, id: null, position: 0});

  const [dynamicFilters, setDynamicFilters] = useState(false);
  const [activeDynamicFilter, setDynamicDateFilter] = useState(generateParamsFormLocation(location.search));

  const [defaultParams, setDefaultParams] = useState(false);
  const [defaultParamsUsed, setDefaultParamsUsed] = useState(false);
  const [lastCompanyType, setLastCompanyType] = useState(companyType);


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }


  const dispatch = useDispatch();
  const queryParams = useQuery();



  useEffect(() => {
    if(companyType !== lastCompanyType){
      setDynamicDateFilter([]);
      setDefaultParams(false);
      setDefaultParamsUsed(false);
    }
    // eslint-disable-next-line
  }, [companyType]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;


    async function fetchShippings() {
      if (loggedCompany) {
        setFetchLoading(true);
        try {

          const r = await getElementBody(getPathParam([COMPANY, loggedCompany.id, BOOKINGS]), signal, 
            {
              limit: limit,
              offset: query.length > 0 ? 0 : offset,
              query: query,
              fulfillment_type: activeGroup,
              shipping_type: shippingType.type,
              remove_default: false, //!defaultParamsUsed,
              company_type: companyType
            },
            activeDynamicFilter
        );


          const bookingId = queryParams.get('booking_id');
          if (bookingId) {
            setManageDetails({ id: bookingId });
            setIsOpenManage(true);
          }

          if(query.length > 0 || offset === 0){
            setList(r.bookings);
            setRows(r.total_rows);
          }else{
            if(r.total_rows > 0 ){
              setList(r.bookings);
              setRows(r.total_rows);
            }else{
              setList([])
            }
          }
          if(r.filters){
            setDynamicFilters(r.filters);
          }

          if(r.default_filter && !defaultParamsUsed && (generateParamsFormLocation(location.search).length === 0)){
            setDefaultParams('?'+r.default_filter);
          }
          setLastCompanyType(companyType);
          setNeedRefresh(false);
        } catch (e) {
          setList([]);
          setRows(0);
          if(signal.aborted){
            setFetchLoading(true);
          }
        } finally {
          if(signal.aborted){
            setFetchLoading(true);
          }else{
            setFetchLoading(false);
          }
        }
      }
    }

    fetchShippings();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [dispatch, offset, query, loggedCompany, companyType, needRefresh, limit, activeDynamicFilter, activeGroup, shippingType]);
 

  return (
    <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.LIST)]} renderBaseOnFail={true}>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Page header */}
          <PageHeader title={t("app.booking.page_title")}>
            <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.CREATE)]}>
              <ActionButton
                icon={
                  <PlusIcon
                    className="w-4 h-4 "
                  />
                }
                onClick={() => {
                  setIsOpen(true);
                }}
                text={t("app.fulfillment.add_new")}
              />
            </BasePermissionWrapper>
          </PageHeader>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">

          <UnderlineTab
            tabs={tabs}
            activeGroup={activeGroup}
            setActiveGroup={setActiveGroup}
          />

          <div className="bg-white shadow-sm rounded-2xl relative">
            <TableHeader 
              extraClass={'rounded-2xl'}
                onSearch={setQuery} 
                showSearch={true} 
                showShippingType={true} 
                shippingTypes={shippingTypes} 
                shippingType={shippingType} 
                setShippingType={setShippingType} 
                isDynamicSearch={false} 
                activeDynamicFilter={activeDynamicFilter}
              >
              <DynamicFilterMenu
                filters={dynamicFilters}
                defaultParams={defaultParams}
                lastCompanyType={lastCompanyType}
                isLoading={isFetchLoading && offset === 0}
                filterType={'fulfillment'}
                onChange={(r) => {
                  setDynamicDateFilter(r);
                  setOffset(0);
                  const querySearch = new URLSearchParams(window.location.search).get("querySearch");
                  if (querySearch) {
                    setQuery(querySearch);
                  } else {
                    setQuery('');
                  }
                  setDefaultParamsUsed(true);
                  setDefaultParams(false);

                }}
              />
              </TableHeader>
          </div>

          <LoaderWrapper isLoading={isFetchLoading}>
            <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-6 ">
              {list.map((element) => (


                <li key={element.id} className="overflow-hidden rounded-xl shadow-md bg-white  border border-gray-200/[0.50]">
                  <div className="flex items-center shadow-sm justify-between gap-x-4 border-b border-gray-900/5 p-3 bg-white hover:bg-gray-50 cursor-pointer" onClick={() => {
                    setManageDetails(element);
                    setIsOpenManage(true);
                  }}>

                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900" >
                        {element.reference}
                      </div>
                    </div>



                    <div className="flex items-center">

                      <div className="mr-2">
                        {
                          element.user && (
                            <UsersToolbar users={[element.user]} isActive={([element.user]).length > 1} extraData={getCustomFormat(element.created_date, 'DD/MM/YYYY')} className="relative">
                              <div className="flex items-center space-x-2">
                                <div className={"flex items-center gap-1 text-sm"}>
                                  <UserInitial user={element.user} ringClass={" ring-yellow-400 "} />
                                </div>
                              </div>
                            </UsersToolbar>
                          )
                        }
                      </div>

                      <StatusBarIcon status={element.status} />
                    </div>


                  </div>
                  <div className="divide-y divide-gray-100  text-sm leading-6 bg-white">
                    <nav className="overflow-y-scroll max-h-56" >
                      <ul className="relative z-0 divide-y divide-gray-200">

                        {
                          (element?.fulfillments ?? []).map((fulfillment) => (
                            <li key={fulfillment.id} className="p-3 bg-gray-100/[0.4] ">
                              <div className="flex justify-between text-sm font-medium">


                                <dt className="max-w-xs">
                                  <div className="flex items-center">
                                    <CustomerCell customer={fulfillment.connection} secondExtra={fulfillment.internal_reference} extra={fulfillment.reference ? fulfillment.reference : ''} />
                                  </div>
                                </dt>

                                <dd className="flex items-center ">
                                  <div className="text-right">
                                    <div className={"flex items-center"}>
                                      <div>
                                        <div className="text-sm text-gray-900 capitalize">
                                          {fulfillment?.destination?.name ?? t("app.container.container_not_defined")}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                          {getFormattedDate(fulfillment.expected_date)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </dd>


                              </div>
                            </li>
                          ))
                        }



                      </ul>
                    </nav>
                  </div>
                </li>


              ))}
            </ul>

          </LoaderWrapper>
        </div>

        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.DELETE)]}>
          {isOpenRemove && (
              <RemovePopup
                  isOpen={isOpenRemove}
                  setIsOpen={setOpenRemove}
                  detail={removeDetail}
                  onRemoveConfirmed={() => {
                    setNeedRefresh(true);
                    dispatch(subscriptionUsageLimits(loggedCompany.id));
                  }}
              />
          )}
        </BasePermissionWrapper>


        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.CREATE)]}>
          {isOpen && <AddBooking isOpen={isOpen} setIsOpen={setIsOpen} />}
        </BasePermissionWrapper>



        <BasePermissionWrapper requiredPermissions={[getPermission(permissionGroup.FULFILLMENT, permissionType.VIEW)]}>
          <ManageBooking isOpen={isOpenManage} setIsOpen={setIsOpenManage} manageDetails={manageDetails} onRefresh={() => { setNeedRefresh(!needRefresh) }} />
        </BasePermissionWrapper>



      </div>
    </BasePermissionWrapper>
  );
};


export default Booking;
