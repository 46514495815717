export function serialize(data) {
  if (typeof data != "object") {
    return "?" + data;
  }
  var str = [];
  for (var p in data) {
    if (data[p] && data.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
    }
  }
  return "?" + str.join("&");
}

export function serializeArray(data) {
  if (!Array.isArray(data)) {
    throw new Error("Invalid data type. Expected array.");
  }
  var str = [];
  for (var i = 0; i < data.length; i++) {
    var obj = data[i];
    for (var p in obj) {
      if (obj[p] && obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
  }
  return "?" + str.join("&");
}


export function concatenateQueryStrings(queryString1, queryString2 ) {
  if(queryString1){
    const searchParams1 = new URLSearchParams(queryString1);
    const searchParams2 = new URLSearchParams(queryString2);

    // Copia tutti i parametri da queryString2 a queryString1
    for (const [key, value] of searchParams2.entries()) {
      searchParams1.set(key, value);
    }

    // Crea la stringa di query concatenata
    return '?' + searchParams1.toString();

  }
  return queryString2;
}

export function removeParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

export function getPathParam(args) {
  var params = [];
  for (var a in args) {
    params.push(args[a].replace("/", ""));
  }
  return params.join("/");
}

export const getWebPathParam = (args) =>
  "/" + args.map((a) => a.replace("/", "")).join("/");


export function getUnitSelectParams(data) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    code: e.code,
    symbol: e.symbol,
    label: e.name + " ("+e.symbol+")",
  }) );
}


export function getUnitSelectParam(data) {
  return {
    id: data.id,
    value: data.id,
    symbol: data.symbol,
    label: data.name + " ("+data.symbol+")",
  };
}

export function getTemplateSelectParams(data) {
  return (data ?? []).map((e) => ({
    value: e.field,
    label: e.field.replace(/_/g, ' ').charAt(0).toUpperCase() + e.field.replace(/_/g, ' ').slice(1).toLowerCase(),
    type: e.type
  }) );
}

export function getTemplateSelectParam(data) {
  return {
    value: data,
    label: data.replace(/_/g, ' ').charAt(0).toUpperCase() + data.replace(/_/g, ' ').slice(1).toLowerCase()
  };
}

export function getSelectParams(data, label) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    code: e?.code ?? null,
    reference: e?.reference ?? null,
    params: e?.params ?? null,
    email_footer: e?.email_footer ?? null,
    type_code: e?.shipping_type?.code ?? null,
    accosciate_entity: e?.accosciate_entity ?? null,
    assigned_users: e?.assigned_users ?? null,
    number_limit: e?.number_limit ?? 0,
    file_link: e?.file?.reference ?? null,
    label: e[label],
    name: e[label]

  }) );
}

export function getCustomSelectParams(data, label) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    code: e?.code ?? null,
    params: e?.params ?? null,
    email_footer: e?.email_footer ?? null,
    type_code: e?.shipping_type?.code ?? null,
    label: e[label],
    data: e
  }) );
}

export function getShippingTypeParams(data) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    type_code: e?.shipping_type?.code ?? null,
    label: e?.name ?? '',
  }) );
}

export function getShippingTypeParam(data) {
  return {
    id: data.id,
    value: data.id,
    type_code: data?.shipping_type?.code ?? null,
    label: data.name,
  };
}


export function getSelectSubParams(data, label, label2) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    code: e?.code ?? null,
    params: e?.params ?? null,
    label: e[label][label2],
  }) );
}

export function getEmailsParams(data, label) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    full_name: e?.full_name ?? null,
    email: e?.email ?? null,
    label: e[label],
  }) );
}

export function getEmailsParam(e, label) {
  return {
    id: e.id,
    value: e.id,
    full_name: e?.full_name ?? null,
    email: e?.email ?? null,
    label: e[label],
  };
}


export function getAttachmentsParams(data) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    label: e.document_type.name + (e.file_note ? ' - ' + e.file_note : ''),
    data: e,
  }) );
}


export function getGenericAttachmentsParams(data) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    label: e.file_name,
    name: e.file_name,
    data: e,
  }) );
}

export function getPortParams(data) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    label: e.name,
    data: e,
  }) );
}

export function getDynamicParams(data) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    label: e.name,
    data: e,
  }) );
}



export function getVesselParams(data) {
  return (data ?? []).map((e) => ({
    id: e.id,
    value: e.id,
    label: e.name,
    data: e,
  }) );
}

export function getVesselParam(data) {
  return {
    id: data.id,
    value: data.id,
    label: data.name,
    data: data,
  }
}

export function getDragDropParams(data, label) {
  return (data ?? []).map((e) => ({
    droppableId: e.id,
    listId: e.id,
    title: e[label],
  }) );
}


export function getSelectParam(data, label) {
  if (data && data.id && data[label]) {
    return { id: data.id, value: data.id, label: data[label], code: data?.code ?? null};
  }
  return {};
}

export function joinClassNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function cleanData(data) {
  if (data) {
    return Object.keys(data).forEach((key) =>
      data[key] === undefined ||
      (typeof data[key] === "object" && Object.keys(data[key]).length === 0)
        ? delete data[key]
        : {}
    );
  }
  return {};
}


export function numberFormat(value, currency = 'EUR'){
  return new Intl.NumberFormat('it', {
    style: 'currency',
    currency: currency
  }).format(value);
}


export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function makeid(length, string = null) {
  var result           = '';
  var characters       = string ? string : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
  }
  return result;
}
